import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./font.css"
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: '1RUU7esULCk3EdxzWT2ZKhc1BYc6y752'
});

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
