import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/home/index')
    },
    {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("@/views/about/index"),
        children: [
            {
                path: "/",
                name: "index",
                redirect: "/aboutus/companyProfile"
            },
            {
                path: "companyProfile",
                name: "companyProfile",
                component: () => import("@/views/about/companyProfile/CompanyProfile"),
            }, {
                path: "corporateCulture",
                name: "CorporateCulture",
                component: () => import("@/views/about/corporateCulture/CorporateCulture"),

            }
        ]
    }, {
        path: "/product",
        name: "Product",
        component: () => import("@/views/product/index"),
    }, {
        path: "/link_us",
        name: "LinkUS",
        component: () => import("@/views/link/linkUs"),
    }, {
        path: "/wechat",
        name: "WeChat",
        component: () => import("@/views/wechat/index"),
    },
    {
        path: "/contactus",
        name: "contactus",
        component: () => import("@/views/wechat/contact"),
    }

]

const router = new VueRouter({
    routes
})

export default router
